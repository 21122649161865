import { useTheme } from '@emotion/react';
import { Body, Box, Button, ConfirmationTile, Heading, IconCheck, Input, Select, Stack, FormLabel } from 'designsystem';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DocschoolApplicationInput, EducationLevelEnum, useDocschoolApplicationMutation } from '../../gql/api';
import { gtm } from 'shared';

const docschoolFormFields: {
    label: string;
    name: keyof DocschoolApplicationInput;
    type: string;
    required?: boolean;
}[] = [
    {
        label: 'Voornaam',
        name: 'firstName',
        type: 'text',
    },
    {
        label: 'Achternaam',
        name: 'lastName',
        type: 'text',
    },
    {
        label: 'Werk e-mail',
        name: 'email',
        type: 'email',
    },
    {
        label: 'Naam school',
        name: 'schoolName',
        type: 'text',
    },
    {
        label: 'Plaats',
        name: 'city',
        type: 'text',
    },
    {
        label: 'Onderwijsniveau',
        name: 'educationLevel',
        type: 'select',
    },
    {
        label: 'Vak',
        name: 'subject',
        type: 'text',
        required: false,
    },
];

const educationLevelOptions: Record<string, { value: EducationLevelEnum; label: string }> = {
    havoVwo: {
        label: 'HAVO/VWO',
        value: EducationLevelEnum.HavoVwo,
    },
    hboWoPabo: {
        label: 'HBO/WO: PABO',
        value: EducationLevelEnum.HboWoPabo,
    },
    mbo: {
        label: 'MBO',
        value: EducationLevelEnum.Mbo,
    },
    primaryEducation: {
        label: 'Primair onderwijs',
        value: EducationLevelEnum.PrimaryEducation,
    },
    specialPrimaryEducation: {
        label: 'Speciaal primair onderwijs',
        value: EducationLevelEnum.SpecialPrimaryEducation,
    },
    specialSecondaryEducation: {
        label: 'Speciaal voortgezet onderwijs',
        value: EducationLevelEnum.SpecialSecondaryEducation,
    },
    vmbo: {
        label: 'VMBO',
        value: EducationLevelEnum.Vmbo,
    },
};

interface DocschoolFormProps {
    formConfirmationHeading: string;
    formConfirmationMessage: string;
}

const DocschoolForm: FC<DocschoolFormProps> = ({ formConfirmationHeading, formConfirmationMessage }) => {
    const formMethods = useForm<DocschoolApplicationInput>();
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted },
    } = formMethods;

    const { mutate, isSuccess, isLoading } = useDocschoolApplicationMutation();

    useEffect(() => {
        if (isSuccess) {
            window.scrollTo(0, 0);
        }
    }, [isSuccess]);

    return (
        <>
            {(!isSubmitted || !isSuccess) && (
                <FormProvider {...formMethods}>
                    <form
                        onSubmit={handleSubmit((values: DocschoolApplicationInput) => {
                            if (isLoading) {
                                return;
                            }
                            gtm.event('form_submit', {
                                form_name: 'docschool',
                            });
                            mutate({
                                input: values,
                            });
                        })}
                    >
                        <Stack spacing={4} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            {docschoolFormFields.map(formField => (
                                <Box key={formField.name}>
                                    <FormLabel htmlFor={formField.name}>{formField.label}</FormLabel>
                                    {(formField.type === 'text' || formField.type === 'email') && (
                                        <Input
                                            type={formField.type}
                                            {...register(formField.name, { required: formField.required ?? true })}
                                        />
                                    )}
                                    {formField.type === 'select' && (
                                        <Select
                                            placeholder={formField.label}
                                            {...register(formField.name, { required: formField.required ?? true })}
                                        >
                                            {formField.name === 'educationLevel' &&
                                                Object.keys(educationLevelOptions).map(key => (
                                                    <option key={key} value={educationLevelOptions[key].value}>
                                                        {educationLevelOptions[key].label}
                                                    </option>
                                                ))}
                                        </Select>
                                    )}
                                </Box>
                            ))}
                        </Stack>

                        <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                            <FormattedMessage defaultMessage="Verzenden" />
                        </Button>
                    </form>
                </FormProvider>
            )}
            {isSubmitted && isSuccess && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconCheck color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>{formConfirmationHeading}</Heading>
                    <Body>{formConfirmationMessage}</Body>
                </ConfirmationTile>
            )}
        </>
    );
};

export default DocschoolForm;
